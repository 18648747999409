import * as React from 'react';

const moment = require('moment');

export interface IProgrammeCard {
  id: string;
  type: string;
  name: string;
  startDateTime: string;
  endDateTime: string;
  description: string;
  img: string;
  redirect: string;
  portalRedirect: boolean;
  callback: any;
  buttonText?: string;
  isDropdownVersion: boolean;
}

/**
 *
 * ProgrammeCard component
 * @extends {React.PureComponent<IProgrammeCard>}
 */
export class ProgrammeCard extends React.PureComponent<IProgrammeCard> {
  public render(): JSX.Element | null {
    const start = this.props.startDateTime !== '' ? new Date(this.props.startDateTime) : null;
    const end = this.props.endDateTime !== '' ? new Date(this.props.endDateTime) : null;
    const time = `${moment(start).format('DD MMM YYYY')}` + `${end !== null && moment(start).format('DD MMM YYYY') !== moment(end).format('DD MMM YYYY') ? ' - ' + moment(end).format('DD MMM YYYY') : ''}`;

    const cardStyle = this.props.isDropdownVersion ? 'ecomm-programme-card dropdown row' : 'ecomm-programme-card row';
    const imgWrapperStyle = this.props.isDropdownVersion ? 'img-wrapper col-6' : 'img-wrapper col-lg-6';
    const contentWrapperStyle = this.props.isDropdownVersion ? 'content-wrapper col-6 row' : 'content-wrapper col-lg-6 row';

    return (
      <div className={cardStyle}>
        <div className={imgWrapperStyle}><img src={this.props.img} /></div>
        <div className={contentWrapperStyle}>
          <div className='col-sm-12'>
            <p className='type'>{this.props.type}</p>
            <p className='name'>{this.props.name}</p>
            <p className='time'>{time}</p>
            {this.props.isDropdownVersion ? null : <p className='description'>{this.props.description}</p>}
          </div>
          <div className='redirect-wrapper col-sm-12'>
            <button className={this.props.portalRedirect ? 'redirect portal' : 'redirect'} onClick={this._handleOnClick}>{this.props.buttonText}</button>
          </div>
        </div>
      </div>
    );
  }

  private _handleOnClick = () => {
    event?.preventDefault();

    if (typeof window !== 'undefined') {

      if(this.props.type == 'Event' || this.props.type == 'Courses')
      {
        window.location.assign(this.props.redirect);
      }
      else
      {
        window.location.assign(this.props.redirect + `${'?id=' + this.props.id}`);
      }
    }
  }
}

export default ProgrammeCard;