import * as React from 'react';

import Modal from 'react-bootstrap/Modal';

interface IEcommPopupProps
{
    showPopup: boolean;
    callback: any;
    name: string;
    isMobile: boolean;
    isPortalPopup: boolean;
    locale: string;
    url: string;
}

/**
 *
 * Event component
 * @extends {React.PureComponent}
 */
export class Popup extends React.PureComponent<IEcommPopupProps> {
  public render(): JSX.Element | null {
    return (
        <Modal show={this.props.showPopup} onHide={this._callbackHide} centered>
            <Modal.Header closeButton={this.props.isPortalPopup ? true : false}>
                <Modal.Title className='ecomm-popup-title'>{this._titleText()}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {this._renderMessage()}
            </Modal.Body>

            <Modal.Footer>
                <a href='' onClick={this._callbackHide} className='ecomm-popup-button ecomm-popup-button-stay' style={this.props.isMobile ? { width: 100 + '%'} : { width: 48 + '%'}}>{this._stayButtonText()}</a>
                <a href={this.props.url} target={this.props.isPortalPopup ? '_blank' : '_self'} onClick={this._callbackRedirect} className={this.props.isPortalPopup ? 'ecomm-popup-button ecomm-popup-button-go-portal' : 'ecomm-popup-button ecomm-popup-button-go'} style={this.props.isMobile ? { width: 100 + '%'} : { width: 48 + '%'}}>{this._goButtonText()}</a>
            </Modal.Footer>
        </Modal>
    );
  }

  private _callbackRedirect = () => {
    this.props.callback();
  }

  private _callbackHide = () => {
    event?.preventDefault();
    this.props.callback();
  }

  private _titleText(): string {
    if (!this.props.isPortalPopup) {
      return this.props.name;
    }

    if (this.props.locale === 'zh') {
      return '网站重定向门户网站';
    } else {
      return 'Commerce Transfer to Portal';
    }
  }

  private _stayButtonText(): string {
    if (this.props.locale === 'zh') {
      if (this.props.isPortalPopup) {
        return '取消';
      } else {
        return '稍后登入';
      }
    } else {
      if (this.props.isPortalPopup) {
        return 'Cancel';
      } else {
        return 'Maybe Later';
      }
    }
  }

  private _goButtonText(): string {
    if (this.props.locale === 'zh') {
      if (this.props.isPortalPopup) {
        return '开启门户网站标签页';
      } else {
        return '登入户口';
      }
    } else {
      if (this.props.isPortalPopup) {
        return 'Proceed to eShop';
      } else {
        return 'Go to Sign In Page';
      }
    }
  }

  private _renderMessage(): JSX.Element | null {
    
    if (this.props.locale === 'zh') {
      if (this.props.isPortalPopup) {
        return (
          <div className='ecomm-popup-body'>
              <p>亲爱的信徒和朋友们，</p>
              <p>&nbsp;</p>
              <p>您将离开这个网站并进入另一个门户网站。</p>
              <p>&nbsp;</p>
              <p>请在离开前完成交易和付款。</p>
              <p>&nbsp;</p>
              <p>愿三宝加持和护佑您!</p>
          </div>
        );
      } else {
        return (
          <div className='ecomm-popup-body'>
            <p>登入账号来查阅交易记录</p>
          </div>
        );
      }
    } else {
      if (this.props.isPortalPopup) {
        return (
          <div className='ecomm-popup-body'>
              <p>Dear Devotees and Friends,</p>
              <p>&nbsp;</p>
              <p>You will be leaving this website and entering another portal.</p>
              <p>&nbsp;</p>
              <p>Please complete the transactions and payments here before you leave.</p>
              <p>&nbsp;</p>
              <p>With Triple Gem Blessings!</p>
          </div>
        );
      } else {
        return (
          <div className='ecomm-popup-body'>
            <p>To see your past transactions, please sign in to your account.</p>
          </div>
        );
      }
    }
  }
}

export default Popup;